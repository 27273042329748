import ApiService from "./ApiService";
import store from "../store";
import AccrualsDetails from "../views/accruals/components/AccrualsDetails";
import AccrualsHistory from "../views/accruals/components/AccrualsHistory";
import preset from "../plugins/vuetify/default-preset/preset/index";
import ApisService from "./ApiService";
import AppService from "./AppService";
import { getAccrualDate, getFormattedDate } from "@/shared/helpers/date";
import UserService from "@/services/UserService";

export default {
  detailsHeaders: [
    {
      text: "Название услуги",
      value: "name",
    },
    {
      text: "Тариф",
      value: "measure_tariff",
    },
    {
      text: "Входящее сальдо",
      value: "payed_before",
    },
    {
      text: "Начислено",
      value: "charged",
    },
    {
      text: "Перерасчет",
      value: "recalculations",
    },
    {
      text: "Платежи",
      value: "sm_payed",
    },
    {
      text: "Исходящее сальдо",
      value: "with_insurance",
    },
    {
      text: "Объем",
      value: "measure_volume",
    },
  ],
  historyHeaders: [
    {
      text: "Месяц",
      value: "month",
    },
    {
      text: "Входящее сальдо",
      value: "payed_before",
    },
    {
      text: "Начислено (с учетом льгот)",
      value: "charged",
    },
    {
      text: "Перерасчеты",
      value: "recalculations",
    },
    {
      text: "Сумма платежа",
      value: "payed",
    },
    {
      text: "Исходящее сальдо",
      value: "with_insurance",
    },
    {
      text: "Квитанция",
      value: "download_epd",
    },
  ],
  paymentsHeaders: [
    {
      text: "Дата",
      value: "date",
    },
    {
      text: "Источник",
      value: "agent",
    },
    {
      text: "Сумма",
      value: "payed",
    },
    {
      text: "Статус",
      value: "status",
    },
  ],

  getTabItems(detailsRows = [], historyRows = []) {
    let lastChargesDate = store.getters["account/lastDate"];

    if (Array.isArray(historyRows)) {
      historyRows.sort((a, b) => {
        const timestampA = Date.parse(a.month);
        const timestampB = Date.parse(b.month);

        if (!isNaN(timestampA) && !isNaN(timestampB)) {
          return timestampB - timestampA;
        } else {
          return 0;
        }
      });
    }

    if (lastChargesDate) {
      lastChargesDate = getAccrualDate(lastChargesDate);
    }

    return [
      {
        tab: `Текущие начисления ${
          lastChargesDate && "(" + lastChargesDate + ")"
        }`,
        component: AccrualsDetails,
        props: {
          rows: detailsRows ? detailsRows : [],
        },
      },
      {
        tab: "Архив начислений",
        component: AccrualsHistory,
        props: {
          rows: historyRows ? historyRows : [],
        },
      },
    ];
  },

  getAvailableHeadersKeys(headersArray) {
    return headersArray.map((item) => item.value);
  },

  getDetailsRows(chargesByService) {
    return this.getRows(
      chargesByService,
      this.getAvailableHeadersKeys(this.detailsHeaders)
    );
  },

  getHistoryRows(charges) {
    return this.getRows(
      charges,
      this.getAvailableHeadersKeys(this.historyHeaders)
    );
  },

  getPaymentsRows(payments) {
    return this.getRows(
      payments,
      this.getAvailableHeadersKeys(this.paymentsHeaders)
    );
  },

  getRows(initialData, availableKeys) {
    const rows =
      initialData &&
      initialData
        .sort((a, b) => Date.parse(b["date"]) - Date.parse(a["date"]))
        .map((item) => {
          const newItem = {};

          availableKeys.forEach((key) => {
            if (key === "date") {
              newItem[key] = getFormattedDate(item[key], "L");
            } else {
              newItem[key] = item[key];
            }
          });

          return newItem;
        });

    return rows ? rows : [];
  },

  async getCharges(accountId) {
    try {
      const charges = await ApiService.fetchAccountCharges(accountId);
      await store.dispatch("account/setCharges", charges);
    } catch (e) {
      console.log(e);
    }
  },

  async getBalance(accountId) {
    try {
      const balance = await ApiService.fetchAccountBalance(accountId);
      store.commit("account/setBalance", balance);
    } catch (e) {
      console.log(e);
    }
  },

  async getPayments(accountId) {
    try {
      const payments = await ApiService.fetchAccountPayments(accountId);
      await store.dispatch("account/setPayments", payments);
    } catch (e) {
      console.log(e);
    }
  },

  getFormattedBalance(balance) {
    if (!balance || Object.keys(balance).length === 0) return null;
    const palette = preset.theme.themes.light;

    let toPay = balance.balance - balance.insurance;
    toPay = !isNaN(toPay) && toPay > 0 ? toPay.toFixed(2) : 0;

    // форматирование
    const charged = !isNaN(balance.charged) ? balance.charged.toFixed(2) : 0;
    const start_debt = !isNaN(balance.start_debt) ? balance.start_debt.toFixed(2) : 0;
    const recalc = !isNaN(balance.recalc) ? balance.recalc.toFixed(2) : 0;
    const penalty = !isNaN(balance.penalty) ? balance.penalty.toFixed(2) : 0;
    const payed = !isNaN(balance.payed) ? balance.payed.toFixed(2) : 0;

    return [
      {
        title: "Начислено",
        value: charged,
        style: {
          "border-radius": "4px 0 0 4px",
        },
      },
      {
        title: "Долг/Переплата(-)",
        value: start_debt,
        style: {
          "background-color": palette.secondary_transparent,
        },
      },
      {
        title: "Перерасчеты",
        value: recalc,
        style: {
          "background-color": palette.secondary_transparent,
        },
      },
      {
        title: "Пени",
        value: penalty,
        style: {
          "background-color": palette.secondary_transparent,
        },
      },
      {
        title: "Оплачено",
        value: payed,
        style: {
          "background-color": palette.secondary_transparent,
        },
      },
      {
        title: "К оплате",
        value: toPay,
        style: {
          "background-color": palette.secondary_transparent,
          "border-radius": "0 4px 4px 0",
        },
      },
      // {
      //   title: 'Оплатить',
      //   value: charges.pay['non_insurance'],
      //   style: {
      //     'background-color': palette.primary,
      //     color: '#fff !important',
      //     'border-radius': '0 4px 4px 0',
      //     cursor: 'pointer'
      //   },
      //   action: () => {
      //     console.log('action')
      //     router.push({name: 'payment'})
      //   }
      // },
      // {
      //   title: 'Льготы',
      //   value: charges.benefits
      // },
      // {
      //   title: 'Пени',
      //   value: charges.penalty
      // }
    ];
  },
  getLastChargesPayWithInsurance(charges) {
    if (charges && charges.pay && charges.pay["with_insurance"]) {
      return charges.pay["with_insurance"];
    }

    return null;
  },

  getLastChargesPayWithoutInsurance(charges) {
    if (charges && charges.pay && charges.pay["non_insurance"]) {
      return charges.pay["non_insurance"];
    }

    return null;
  },

  async downloadReceipt() {
    const id = store.getters["user/activeBindingId"];
    const file = await ApisService.downloadReceipt(id);
    const blob = new Blob([file], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "ЕПД";
    link.click();
    URL.revokeObjectURL(link.href);
    AppService.unsetLoading("get_epd");
  },

  async downloadReceiptByDate(date) {
    const id = store.getters["user/activeBindingId"];
    const file = await ApisService.downloadReceiptByDate(id, date);
    const blob = new Blob([file], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "ЕПД";
    link.click();
    URL.revokeObjectURL(link.href);
    // AppService.unsetLoading("get_epd_" + date);
  },

  sendEpdRequest(accountId, payload) {
    try {
      ApiService.sendEpdRequest(accountId, payload).then(() => UserService.getUserBindings());
    } catch (e) {
      console.log(e);
    }
  },
};
