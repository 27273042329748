import StorageService from "../../services/StorageService";
import { hasProperty } from "@/shared/helpers/common";

export default {
  namespaced: true,
  state: () => ({
    charges: StorageService.getItem("charges"),
    payments: StorageService.getItem("payments"),
    counters: [],
    indications: [],
    balance: [],
    recurrents: [],
    gp: [],
    debt: [],
  }),
  mutations: {
    setCharges(state, payload) {
      state.charges = payload;
    },
    setPayments(state, payload) {
      state.payments = payload;
    },
    setCounters(state, payload) {
      state.counters = payload;
    },
    setIndications(state, payload) {
      state.indications = payload;
    },
    setBalance(state, payload) {
      state.balance = payload;
    },
    setRecurrents(state, payload) {
      state.recurrents = payload;
    },
    setGP(state, payload) {
      state.gp = payload;
    },
    setDebt(state, payload) {
      state.debt = payload;
    },
  },
  actions: {
    setCharges({ commit }, payload) {
      commit("setCharges", payload);
      StorageService.setItem("charges", payload);
    },
    setPayments({ commit }, payload) {
      commit("setPayments", payload);
      StorageService.setItem("payments", payload);
    },
    setCounters({ commit }, payload) {
      commit("setCounters", payload);
    },
    setIndications({ commit }, payload) {
      commit("setIndications", payload);
    },
    setRecurrents({ commit }, payload) {
      commit("setRecurrents", payload);
    },
    setGP({ commit }, payload) {
      commit("setGP", payload);
    },
    setDebt({ commit }, payload) {
      commit("setDebt", payload);
    },
  },
  getters: {
    lastDate: (state) => state.charges && state.charges["last_date"],
    lastCharges: (state, getters) => {
      const lastDate = getters.lastDate;
      if (!lastDate) return null;

      return state.charges[lastDate];
    },
    total: (state, getters) => {
      const lastCharges = getters.lastCharges;
      if (!lastCharges) return null;

      return lastCharges.pay["non_insurance"];
    },
    totalWithInsurance: (state, getters) => {
      const lastCharges = getters.lastCharges;
      if (!lastCharges) return null;

      return lastCharges.pay["with_insurance"];
    },
    lastChargesByService(state, getters) {
      const lastCharges = getters.lastCharges;
      if (
        !lastCharges ||
        !lastCharges.details ||
        !lastCharges.details["by_service"]
      )
        return null;
      return lastCharges.details["by_service"];
    },
    chargesByService: (state) => (date) => {
      if (
        state.charges &&
        state.charges[date] &&
        state.charges[date].details &&
        state.charges[date].details["by_service"]
      ) {
        return state.charges[date].details["by_service"];
      } else {
        return [];
      }
    },
    chargeItems: (state) => {
      if (state.charges) {
        const newCharges = [];
        const keysToIgnore = ["pay", "details"];

        for (let key in state.charges) {
          if (
            hasProperty(state.charges, key) &&
            typeof state.charges[key] === "object"
          ) {
            const newCharge = {
              month: key,
              with_insurance: state.charges[key].pay?.with_insurance,
            };

            for (let chargeKey in state.charges[key]) {
              const item = state.charges[key][chargeKey];
              if (
                !keysToIgnore.includes(chargeKey) &&
                hasProperty(state.charges[key], chargeKey)
              ) {
                newCharge[chargeKey] = item;
              }
            }

            newCharges.push(newCharge);
          }
        }

        return newCharges;
      }

      return [];
    },
    getCounterById: (state) => (id) => {
      return state.counters.find((counter) => counter.id === id);
    },
    recurrents: (state) => state.recurrents,
    gp: (state) => state.gp,
    debt: (state) => state.debt,
  },
};
