<template>
  <v-app>
    <vertical-nav-menu
      v-if="isLoggedIn && isMobile"
      :is-drawer-open.sync="isDrawerOpen"
    ></vertical-nav-menu>
    <app-header
      :is-drawer-open.sync="isDrawerOpen"
      v-if="isLoggedIn"
      :app-content-container-style="appContentContainerStyle"
    ></app-header>
    <v-main>
      <div
        class="app-content-container boxed-container"
        :style="appContentContainerStyle"
      >
        <router-view />
      </div>
      <x-alert v-if="showAlert"></x-alert>
      <result-dialog></result-dialog>
    </v-main>
  </v-app>
</template>

<script>
import AppHeader from "./components/Layouts/app-header/AppHeader";
import XAlert from "./components/Alert";
import AppService from "@/services/AppService";
import VerticalNavMenu from "@/components/Layouts/vertical-nav-menu/VerticalNavMenu";
import AuthService from "./services/AuthService";
import { mapState } from "vuex";
// import StoreService from "./services/StorageService";
import ResultDialog from "./components/dialogs/ResultDialog";

export default {
  name: "App",
  components: {
    ResultDialog,
    AppHeader,
    VerticalNavMenu,
    XAlert,
  },
  data() {
    return {
      isDrawerOpen: false,
      containerPadding: this.$vuetify.breakpoint.smAndUp ? "1em" : "auto",
    };
  },
  computed: {
    isLoggedIn() {
      return AuthService.isLoggedIn;
    },
    ...mapState("app", {
      showAlert: (state) => state.alert.shown,
      // update: (state) => {
      //   return state.update
      // }
    }),
    appContentContainerStyle() {
      return {
        padding: `0 ${this.containerPadding}`,
      };
    },
    ...mapState("app", {
      isMobile: (state) => state.isMobile,
    }),
  },
  methods: {
    onResize() {
      AppService.setIsMobile(window.innerWidth < 600);
    },
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  async beforeCreate() {
    const isLoggedIn = AppService.loadStoredState();
    if (isLoggedIn) {
      await AppService.init();
    }
  },
};
</script>
<style lang="scss">
@import url("~@mdi/font/css/materialdesignicons.css");
.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 599.98px) {
  .app-content-container .v-btn:not(.full-button) {
    margin-top: 10px;
  }
  .v-application .v-main {
    margin-bottom: 15px;
    /*padding-top: 190px !important;*/
  }
  /*.v-application .v-btn:not(.d-block) {
    margin-top: 10px !important;
  }*/
  /*.v-main {
    padding-top: 200px !important;
  }*/
}
</style>
