<template>
  <no-auth-form-layout class="pay-no-auth">
    <template v-slot:image>
      <div class="images-container d-flex justify-center w-full">
        <div class="image card-image"></div>
        <div class="image phone-image"></div>
      </div>
    </template>
    <template v-slot:title>
      <div class="text-break text-center">Узнать сумму последней квитанции и оплатить</div>
    </template>
    <template v-slot:form>
      <div>
        <simple-form :form-instance="form"></simple-form>
        <payment-additional-info
          v-if="!form.isCheckLsState"
        ></payment-additional-info>
      </div>
    </template>
    <template v-slot:additional-links>
      <div>
        <router-link :to="{ name: 'login' }">Авторизоваться</router-link>
      </div>
    </template>
  </no-auth-form-layout>
</template>

<script>
import SimpleForm from "@/components/forms/SimpleForm";
import PayNoAuthForm from "@/forms/no_auth/PayNoAuthForm";
import NoAuthFormLayout from "@/components/Layouts/forms/NoAuthFormLayout";
import PaymentAdditionalInfo from "../payment/PaymentAdditionalInfo";

export default {
  name: "PayNoAuth",
  components: { NoAuthFormLayout, SimpleForm, PaymentAdditionalInfo },
  data() {
    return {
      form: new PayNoAuthForm(),
    };
  },
  mounted() {
    if (this.$route.query.account) {
      this.form.payload.account = this.$route.query.account;
    }
  },
};
</script>

<style lang="scss">
.pay-no-auth {
  .images-container {
    height: 400px;

    .image {
      &.card-image {
        width: 35%;
        background: url("../../assets/images/card-blur.png") no-repeat right 0
          top 25%;
        background-size: 75%;
      }

      &.phone-image {
        width: 65%;
        background: url("../../assets/images/phone.png") no-repeat left 0 top
          40%;
        background-size: 85%;
      }
    }
  }
}
</style>
